/* eslint-disable no-console */

<template>
  <div id="app">  
    <div v-if="!isLoggedIn">
      <div>v0.33</div><br /><br />
      Passwort: <input type="password" v-model="pwd" v-on:keyup.enter="enterPage" />
      <br>
      <button @click="enterPage">Enter</button>
    </div>
    <!--<button @click="printTracks">Print</button>
    <button @click="changeAudioOut">AudioOut</button>
    <button @click="getDevices">GetDevices</button>-->

    <div v-if="!isStarted && isLoggedIn"> 
      <div>Auf der nächsten Seite kannst du deine Kamera wählen.</div>
      <div>Damit das funktioniert musst du bitte dem Browser Zugriff auf Mikrofon und Kamera gestatten</div>
      <div><b>iPhones funktionieren jetzt auch!</b></div>
      <button @click="getStarted">Start</button>
    </div>
    <div v-if="isStarted">
      <select v-model="devices.selected.videoIn" @change="createLocalTracks">
          <option v-for="option in devices.available.videoIn" v-bind:value="option.deviceId" v-bind:key="option.deviceId">
            {{ option.label }}
          </option>
        </select>
        <select v-model="devices.selected.audioIn" @change="createLocalTracks">
          <option v-for="option in devices.available.audioIn" v-bind:value="option.deviceId" v-bind:key="option.deviceId">
            {{ option.label }}
          </option>
        </select>
        <!-- <select v-model="devices.selected.audioOut">
          <option v-for="option in devices.available.audioOut" v-bind:value="option.deviceId" v-bind:key="option.deviceId">
            {{ option.label }}
          </option>
        </select> -->
        <div v-if="gridView">
          <input type="range" min="50" max="300" v-model="vidSizeSlider" />
          {{vidSizeSlider}}px
        </div>     
    </div>
    <div v-if="isStarted" style="text-align: left; display: flex; align-items: center; margin-bottom: 10px;">
      <video ref="localVideo" autoplay muted playsinline />
      <!-- <audio ref="localAudio" autoplay muted /> -->
      <button @click="toggleMute" >{{muteButtonText}}</button>  
      <br>
      <button @click="muteAllTracks" >{{muteTracksButtonTest}}</button>
    </div>
    <div v-if="isStarted && !isConnected"> 
      <div>Noch kann dich keiner sehen!</div>
      <div>Wähle deine Kamera und dein Mikrofon</div>
      <div>Klicke auf connect, ab dann bist du sichtbar!</div>
      
      <div>
        <button @click="connect">Connect</button>
      </div>
    </div>    
    <div v-if="gridView" style="text-align: left;">
      <video v-for="entry in remoteTracksFiltered" :key="entry.participant" :ref="'video' + entry.participant" autoplay muted playsinline :style="gridVideoSize" />
    </div>
    <div v-if="isConnected && ! gridView">
      <video ref="masterVideo" autoplay muted playsinline style="max-width: 100%; max-height: 100%"/>
    </div>

    <div v-for="entry in remoteTracksFiltered" :key="'cbx_' + entry.participant">
      <!-- <input type="checkbox" v-model="entry.checked" >-->
      <label > {{entry.participant}}</label>
      <label v-if="entry.videoTracks.length > 0"> | {{entry.videoTracks[0].track.id}}</label>
      <label v-if="entry.stats.videoSize" > {{entry.stats.videoSize}}</label>
      <label v-if="entry.stats.bitrate" > {{entry.stats.bitrate.download}} / {{entry.stats.bitrate.upload}}</label>
    </div>
   <!-- <button v-if="remoteTracks.length > 0" @click="updateConstraints" >Select Streams</button>-->

    <div v-for="entry in remoteTracks" :key="entry.participant">
      <!--<div>{{entry.participant}}</div>
      <video v-if="entry.videoTracks.length > 0" :ref="'video' + entry.participant" autoplay />-->
      <audio v-if="entry.audioTracks.length > 0" :ref="'audio' + entry.participant" autoplay />
    </div>
    <div>{{error}}</div>
  </div>
</template>

<script>

import Jitsi from '@/jitsi/jitsiUtils'

export default {
  name: 'JitsiClient2',
  data() {
    return {
      audioVolume: 1,
      vidSizeSlider: 150,
      isMuted: false,
      pwd: "",
      gridView: false,
      masterStream: false,
      masterId: undefined,
      isStarted: false,
      isConnected: false,
      isLoggedIn: false,
      localTracks: {
        video: [],
        audio: []
      },
      remoteTracks: [],
      devices: {
        available: {},
        selected: {
          videoIn: '',
          audioIn: '',
          audioOut: ''
        }
      },
      error: ""
    }
  },
  beforeUnmount() {
    Jitsi.disconnect();
  },
  mounted() {
    // this.getDevices();
  },
  computed: {
    remoteTracksFiltered() {
      return this.remoteTracks.filter( x => x.videoTracks.length > 0 );
    },
    muteButtonText() {
      return this.isMuted ? "Unmute Mic" : "Mute Mic";
    },
    gridVideoSize() {
      return "max-width: " + this.vidSizeSlider + "px;"
    },
    muteTracksButtonTest() {
      return this.audioVolume > 0.5 ? "Mute Output" : "Unmute Output";
    }
  },
  methods: {
    muteAllTracks( ) {
      let volume = this.audioVolume > 0.5 ? 0 : 1;

      for( let idx in this.remoteTracks ) {
        let p = this.remoteTracks[idx].participant;
        let id = 'audio' + p;
        if( this.$refs[id] )
          this.$refs[id][0].volume = volume;         
      }

      this.audioVolume = volume;
    },
    testVTR() {
        Jitsi.selectParticipants( [ this.masterId ] );
    },
    getWidth( participant ) {
        let id = 'video' + participant;

        if( ! this.gridView && this.$refs[ 'masterVideo' ]) {
          return { w: this.$refs[ 'masterVideo' ].videoWidth, h: this.$refs[ 'masterVideo' ].videoHeight };
        } else if( this.$refs[ id ] )
          return { w: this.$refs[ id ][0].videoWidth, h: this.$refs[ id ][0].videoHeight };
        else
          return { w: 0, h: 0 };
    },
    updateConstraints() {
      console.log("update");

      let pIds = [];
      for( let idx in this.remoteTracks ) {
        if( this.remoteTracks[idx].checked === true ) {
          pIds.push( this.remoteTracks[idx].participant );
        }
      }

      let vtr = {
        'lastN': -1, // Number of videos requested from the bridge.
        'selectedEndpoints': pIds, // The endpoints ids of the participants that are prioritized first.
        'onStageEndpoints': pIds, // The endpoint ids of the participants that are prioritized up to a higher resolution.
        'defaultConstraints': { 'maxHeight': 0 }, // Default resolution requested for all endpoints.
        'constraints': { } // Endpoint specific resolutions
      };

      for( let idx in pIds ) {
          vtr.constraints[ pIds[idx] ] = { 'maxHeight': 1080 };
      }

      console.log( vtr );
      Jitsi.setReceiverConstraints( vtr );
    },
    setGridConstraints() {
        let vtr = {
              'lastN': 500, // Number of videos requested from the bridge.
              'selectedEndpoints': [ ], // The endpoints ids of the participants that are prioritized first.
              'onStageEndpoints': [ ], // The endpoint ids of the participants that are prioritized up to a higher resolution.
              'defaultConstraints': { 'maxHeight': 180 }, // Default resolution requested for all endpoints.
              'constraints': { } // Endpoint specific resolutions
        };
    },
    async toggleMute( doMute ) {    
      if( this.localTracks.audio.length > 0 ) {
        let track = this.localTracks.audio[0];

        if( doMute === undefined || doMute.type !== undefined )
          doMute = ! track.isMuted();

        console.log( track );
        console.log( doMute );
        if( doMute ) {     
          await track.mute();
        } else {
          await track.unmute();
        }
      }
    },
    enterPage() {
      if( this.pwd === "ict1020") {
        this.isLoggedIn = true;
      } else if( this.pwd === "flamingo" ) {
        this.isLoggedIn = true;
        this.gridView = true;
      } else if( this.pwd === "mcnico" ) {
        this.isLoggedIn = true;
        this.gridView = true;
        this.masterStream = true;
      } else {
        this.pwd = "";
      }
    },
    getStarted() {
      this.createLocalTracks();
      this.isStarted = true;
    },
    async createLocalTracks( ) {
      let newTracks = await Jitsi.createLocalTracks( this.devices.selected );

      if( newTracks.video.length > 0 ) {
        this.localTracks.video = newTracks.video;
        newTracks.video[0].attach( this.$refs['localVideo'] );
        this.devices.available.videoIn = newTracks.video[0].deviceId;
      }
      
      if( newTracks.audio.length > 0 ) {
        this.localTracks.audio = newTracks.audio;
        // newTracks.audio[0].attach( this.$refs['localAudio'] );
        newTracks.audio[0].addEventListener(
                Jitsi.jitsiMeet.events.track.TRACK_MUTE_CHANGED,
                ( params ) =>  { this.isMuted = params.isMuted() } );

        this.devices.available.audioIn = newTracks.audio[0].deviceId;
      }

      this.getDevices();
    },
    async getDevices() {
      this.devices.available = await Jitsi.getDevices();

      if( this.devices.available.videoIn.length > 0 && this.devices.selected.videoIn.length < 1 )
        this.devices.selected.videoIn = this.devices.available.videoIn[0].deviceId;
      if( this.devices.available.audioIn.length > 0 && this.devices.selected.audioIn.length < 1 )
        this.devices.selected.audioIn = this.devices.available.audioIn[0].deviceId;
      if( this.devices.available.audioOut.length > 0 && this.devices.selected.audioOut.length < 1 )
        this.devices.selected.audioOut = this.devices.available.audioOut[0].deviceId;
    },
    async connect() {
      if( this.isConnected === true )
        return;

      try {
        Jitsi.Events.on('localTrackAdded', track => { console.log("TRACK ADDED"); console.log( track ); });
        let connection = {};
        let room = {};

        try {
          connection = await Jitsi.connect();
          this.isConnected = true;
          room = await Jitsi.createAndJoinRoom(connection, 'hogmshjy-raum1', this.masterStream ? "xusnak" : undefined );
        } catch( error ) {
          this.isConnected = false;
          return;
        }
        
        let parts = room.getParticipants();
        console.log( parts );

        if( this.gridView ) {
          this.setGridConstraints();
        } else {
          for( let idx in parts ) {
            if( parts[idx]._displayName === "xusnak" ) {
                this.masterId = parts[idx]._id;
                Jitsi.selectParticipants( [this.masterId] );
            }
          }
        }
        
        this.toggleMute( true );
        /*
        if( room.isStartAudioMuted() ) {
          this.toggleMute( true );
          console.log("Mute" );
        } else {
          console.log("Mute not requested" );
        }*/

        room.on( Jitsi.jitsiMeet.events.conference.CONFERENCE_ERROR, error => { this.error += "\n" + error; console.log("ERROR ROOM" ); console.log( error ); } );
        // connection.on( Jitsi.jitsiMeet.events.conference.CONFERENCE_ERROR, error => { this.error += "\n" + error; console.log("ERROR CONN" ); console.log( error ); } );


        room.on( Jitsi.jitsiMeet.events.conference.TRACK_ADDED, track => { console.log("TA2" ); console.log( track ); this.addRemoteTrack(track) } );


        room.on( Jitsi.jitsiMeet.events.connectionQuality.LOCAL_STATS_UPDATED, stats => {
            // console.log( stats );
        });
        room.on( Jitsi.jitsiMeet.events.connectionQuality.REMOTE_STATS_UPDATED, (id,stats) => {
            stats.videoSize = this.getWidth( id );

            let p = this.remoteTracks.find( x => x.participant === id );
            if( p ) {
              this.$set(p, 'stats', stats);
              // p.stats = stats;
            } else {
              console.log( "participant not found" );
            }
        });



        room.on( Jitsi.jitsiMeet.events.conference.TRACK_REMOVED, track => {
            console.log(`track removed!!! ${track}`);
        });
        room.on( Jitsi.jitsiMeet.events.conference.USER_JOINED, (id, user) => {
            console.log('user join ' + id);
            console.log( user );
            if( user._displayName === "xusnak" && ! this.gridView ) {
              this.masterId = id;
              Jitsi.selectParticipants( [ id ] );
            }
        });

        room.on(Jitsi.jitsiMeet.events.conference.TRACK_MUTE_CHANGED, track => {
            console.log(`${track.getType()} - ${track.isMuted()}`);
        });
        room.on(
            Jitsi.jitsiMeet.events.conference.DISPLAY_NAME_CHANGED,
            (userID, displayName) => console.log(`${userID} - ${displayName}`));
        room.on(
            Jitsi.jitsiMeet.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
            (userID, audioLevel) => console.log(`${userID} - ${audioLevel}`));
        room.on(
            Jitsi.jitsiMeet.events.conference.PHONE_NUMBER_CHANGED,
            () => console.log(`${room.getPhoneNumber()} - ${room.getPhonePin()}`));
            
        room.on( Jitsi.jitsiMeet.events.conference.USER_LEFT, id => this.onUserLeft(id) );

        // Jitsi.createTracksAndAddToRoom(room);
      
        Jitsi.jitsiMeet.mediaDevices.addEventListener(
            Jitsi.jitsiMeet.events.mediaDevices.DEVICE_LIST_CHANGED,
            this.onDeviceListChanged );

      } catch ( error ) {
        console.error(error);
        this.error += "\n" + error;
      }
    },
    onDeviceListChanged(devices) {
        // this.devices = devices,
        this.error += "\nDeviceListChanged";
        console.info('current devices', devices);
    },
    changeAudioOut() {
      Jitsi.changeAudioOutput( this.devices.selected.audioOut );
    },
    printTracks() {
      console.log( this.remoteTracks );
    },
    onUserLeft(id) {
        console.log('user left ' + id );
        let idx = this.remoteTracks.findIndex( x => x.participant === id );
        if( idx < 0 )
          return;

        let part = this.remoteTracks[idx];
        if( part.videoTracks.length > 0 )
          this.detachTrack( part.videoTracks[0], this.gridView ? 'video' + part.participant : 'masterVideo' );
        if( part.audioTracks.length > 0 )
          this.detachTrack( part.audioTracks[0], 'audio' + part.participant );   

        this.remoteTracks.splice( idx, 1 );
    },
    detachTrack( track, trackId ) {
      let shortId = trackId === 'masterVideo';
      try {
        track.detach( shortId ? this.$refs[ trackId ] : this.$refs[ trackId ][0] );
      } catch( error ) {
        console.warn("DETACH-ERROR: " + trackId );
        console.warn( this.$refs );
      }   
    },
    addRemoteTrack( track ) {
      console.log( track );
      console.log("1" );
      if (track.isLocal()) {
        return;
      }

      const participant = track.getParticipantId();
      
      if (!this.gridView && track.getType() === 'video' && participant !== this.masterId ) {      
          return; 
      }

      console.log("2" );

      let part = this.remoteTracks.find( x => x.participant === participant );
      if (! part ) {
          part = {
            audioTracks: [],
            videoTracks: [],
            participant: participant,
            stats: { }
          };

          this.remoteTracks.push( part );
          console.log("PUSH " + participant );
      }
      //const idx = this.remoteTracks[participant].push(track);
      
      track.addEventListener( Jitsi.jitsiMeet.events.track.TRACK_AUDIO_LEVEL_CHANGED,
          audioLevel => console.log(`Audio Level remote: ${audioLevel}`));
      track.addEventListener( Jitsi.jitsiMeet.events.track.TRACK_MUTE_CHANGED,
          () => console.log('remote track muted'));
      track.addEventListener( Jitsi.jitsiMeet.events.track.LOCAL_TRACK_STOPPED,
          () => console.log('remote track stoped'));
      track.addEventListener( Jitsi.jitsiMeet.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
          deviceId =>
              console.log( `track audio output device was changed to ${deviceId}`));

      // const id = participant + track.getType() + idx;

      console.log("3" );
      let id = participant;
      let shortRef = false;
      if (track.getType() === 'video') {
          part.videoTracks.push(track);
          id = 'video' + participant;

          if( !this.gridView ) {
            shortRef = true;
            id = 'masterVideo';
            console.log( "added master" );
          }
      } else if (track.getType() === 'audio') {
          part.audioTracks.push(track);
          id = 'audio' + participant;
      }
      this.$nextTick().then(() => {
        try {
          let ref = shortRef ? this.$refs[ id ] : this.$refs[ id ][0];
          
          // track.track.muted = false;
          track.attach( ref );
          console.log( ref );
          console.log( track );

          if( track.getType() === 'audio' )
            ref.volume = this.audioVolume;
          // ref.play();
        } catch( error ) {
          console.warn("ATTACH-ERROR: " + id );
          console.warn( this.$refs );
        }
      });
    }
  },
}
</script>

<style>
video {
  max-width: 150px;
  margin: 0px;
  padding: 0px;
}
</style>