/* eslint-disable no-console */

import JitsiMeetJS from '@/jitsi/lib';
import $ from 'jquery';
window.$ = $;

let connection = null;
let isJoined = false;
let room = null;

let localTracks = [];

let testArr = [];

function AddToTest() {
    testArr.push({ name: "thomas" });
}

const initOptions = {
    disableAudioLevels: true
};

const connectOptions = {
    hosts: {
        domain: 'meet2.hybridvenue.live',
        muc: 'conference.meet2.hybridvenue.live' // FIXME: use XEP-0030
    },
    serviceUrl: 'https://meet2.hybridvenue.live/http-bind', // FIXME: use xep-0156 for that

    // The name of client node advertised in XEP-0115 'c' stanza
    clientNode: 'http://jitsi.org/jitsimeet',
};

const confOptions = {
    startAudioMuted: true, 
    enableLayerSuspension: false,
    p2p: { enabled: false },
};



JitsiMeetJS.init( initOptions );
JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

function setReceiverConstraints( constraints ) {
    room.setReceiverConstraints( constraints ); 
}

function selectParticipants( participantIds ) {
    let vtr = {
        'lastN': -1, // Number of videos requested from the bridge.
        'selectedEndpoints': participantIds, // The endpoints ids of the participants that are prioritized first.
        'onStageEndpoints': participantIds, // The endpoint ids of the participants that are prioritized up to a higher resolution.
        'defaultConstraints': { 'maxHeight': 0 }, // Default resolution requested for all endpoints.
        'constraints': { } // Endpoint specific resolutions
      } 

      for( let idx in participantIds ) {
          vtr.constraints[ participantIds[idx] ] = { 'maxHeight': 1080 };
      }

      console.log("VTR");
      console.log( vtr );
      let r = room.setReceiverConstraints( vtr );
      console.log( r);     
}

async function createLocalTracks( selectedDevices ) {
    try {
        let localTrackOptions = { 
            devices: [ ],
            constraints: {
                video: {
                    aspectRatio: 16 / 9,
                    height: {
                        ideal: 720,
                        max: 1080,
                        min: 180
                    },
                    frameRate: {
                        ideal: 30,
                        max: 60,
                        min: 15
                    }
                }
            },        
        };

        if( selectedDevices.videoIn && selectedDevices.videoIn.length > 0 ) {
            localTrackOptions.devices.push( 'video' );
            localTrackOptions.cameraDeviceId = selectedDevices.videoIn;
        }
        
        if( selectedDevices.audioIn && selectedDevices.audioIn.length > 0 ) {
            localTrackOptions.devices.push( 'audio' );
            localTrackOptions.micDeviceId = selectedDevices.audioIn;
        }

        if( localTrackOptions.devices.length < 1 )
            localTrackOptions.devices =  [ 'video', 'audio' ];

        for( let t in localTracks ) {
            await localTracks[t].dispose();
        }

        localTracks = await JitsiMeetJS.createLocalTracks( localTrackOptions );

        let tracks = {
            video: [],
            audio: []
        };

        for (let i = 0; i < localTracks.length; i++) {
            localTracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
                audioLevel => console.log(`Audio Level local: ${audioLevel}`));
            localTracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
                () => console.log('local track muted'));
            localTracks[i].addEventListener(
                JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                () => console.log('local track stoped'));
            localTracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
                deviceId =>
                    console.log(
                        `track audio output device was changed to ${deviceId}`));

            if (localTracks[i].getType() === 'video') {
                tracks.video.push( localTracks[i] );
            } else {
                tracks.audio.push( localTracks[i] );
            }

            if (isJoined) {
                room.addTrack(localTracks[i]);
            }
        }

        return tracks;
    } catch ( error ) {
        console.error('There was an error creating the local tracks:', error);
        throw( error );
    }  
}
  
function createAndJoinRoom(connection, roomName, userName ) {
    console.log( "ROOMR" );
    return new Promise((resolve) => {
        room = connection.initJitsiConference(roomName, confOptions ); 

        room.on( JitsiMeetJS.events.conference.TRACK_ADDED, () => { console.log("TA") } ); 
        room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {       
            isJoined = true;
            for (let i = 0; i < localTracks.length; i++) {
                room.addTrack(localTracks[i]);
            }

            resolve(room);
        });
        
        if( userName )
            room.setDisplayName( userName );
            
        room.join();
    });
}

async function getDevices() {
    return new Promise( (resolve) => {
        var devs = {
            audioIn: [],
            videoIn: [],
            audioOut: [],
            others: []
        };

        try {
            if(JitsiMeetJS.mediaDevices.isDeviceListAvailable() ) {
                JitsiMeetJS.mediaDevices.enumerateDevices(devices => {      
                    devs.audioIn = devices.filter(d => d.kind === 'audioinput');
                    devs.audioOut = devices.filter(d => d.kind === 'audiooutput');
                    devs.videoIn = devices.filter(d => d.kind === 'videoinput');
                    devs.others = devices.filter(d => d.kind !== 'audioinput' && d.kind !== 'audiooutput' && d.kind !== 'videoinput' );
                    
                    resolve( devs );           
                });
            } else {
                resolve( devs );
            }
        } catch(error) {
            console.log( "ERROR GETTING DEVICES" );
            console.log( error );
            resolve( devs );
        }
    });
}
  
function connect( ) {
    return new Promise(((resolve, reject) => {
        //let optionsWithRoom = { ...options };
        //optionsWithRoom.serviceUrl = options.serviceUrl + `?room=${roomName}`;

        connection = new JitsiMeetJS.JitsiConnection(null, null, connectOptions);

        connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, () => {
            resolve(connection);
        });

        connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, () => {
            reject('The connection failed.');
        });

        /*
        connection.addEventListener( Jitsi.jitsiMeet.events.connectionQuality.LOCAL_STATS_UPDATED, stats => {
            console.log(`CONN LOCAL_STATS_UPDATED!!!`);
            console.log( stats );
        });
        connection.addEventListener( Jitsi.jitsiMeet.events.connectionQuality.REMOTE_STATS_UPDATED, (id,stats) => {
            console.log(`CONN REMOTE_STATS_UPDATED!!!`);
            console.log( id );
            console.log( stats );
        });
*/

        connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, () => { 
            console.log("Connection disconnected");
            /*connection.removeEventListener(
                JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
                onConnectionSuccess);
            connection.removeEventListener(
                JitsiMeetJS.events.connection.CONNECTION_FAILED,
                onConnectionFailed);
            connection.removeEventListener(
                JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
                disconnect); */
        });

        connection.connect();
    }))
}

function disconnect() {
    for (let i = 0; i < localTracks.length; i++) {
        localTracks[i].dispose();
    }

    room.leave();
    connection.disconnect();
}

function changeAudioOutput( deviceId ) { // eslint-disable-line no-unused-vars
    JitsiMeetJS.mediaDevices.setAudioOutputDevice( deviceId );
}

const eventHandler = {
    handlers: {},
  
    on(eventName, handler) {
      if (!this.handlers[eventName])
        this.handlers[eventName] = [];
  
      this.handlers[eventName].push(handler);
    },
  
    emit(eventName, data) {
      for (const handler of this.handlers[eventName])
        handler(data);
    }
  };

  
export default {
    selectParticipants: selectParticipants,
    testArr: testArr,
    AddToTest: AddToTest,
    jitsiMeet: JitsiMeetJS,
    createAndJoinRoom: createAndJoinRoom,
    createLocalTracks: createLocalTracks,
    connect: connect,
    disconnect: disconnect,
    getDevices: getDevices,
    changeAudioOutput: changeAudioOutput,
    setReceiverConstraints: setReceiverConstraints,
    Events: eventHandler
}
