<template> 
  <div id="app">
    <jitsi-client-2></jitsi-client-2>
  </div>
</template>

<script>
import JitsiClient2 from './components/JitsiClient2.vue'
// import HelloJitsi from '@/components/HelloJitsi.vue';

export default {
  components: { JitsiClient2 },
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>